import { ThrowStmt } from "@angular/compiler";
import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Chart } from "chart.js";
import "chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js";
import "chartjs-gauge";
import html2canvas from "html2canvas";
import * as html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import { Observable, Subscription, fromEvent } from "rxjs";
import {
  IEyyo,
  INutrientAdvice,
  IProbioticPrebioticAdvice,
} from "../../../interfaces/advice";
import BacteriaSubCategories from "../../assets/json/bacteriaSubCategories.json";
import { DashboardService } from "../api/calculations/dashboard";
import { DietaryDashService } from "../api/calculations/dietarydash";
import { UtilsService } from "../api/utils/utils";
import { AppComponent } from "../app.component";
import { TestKitIdService } from "../testkit-id-get/testkitid.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  @Input() hidePdfButton: boolean;
  takeHomeData: Object = {
    focusCategories: [],
    meals: {
      breakfast: 0,
      lunch: 0,
      dinner: 0,
    },
    foodRecommendation: [],
  };
  constructor(
    public dashboardService: DashboardService,
    private route: ActivatedRoute,
    private router: Router,
    public app: AppComponent,
    private translate: TranslateService,
    public dietaryDashService: DietaryDashService,
    private testKitIdService: TestKitIdService,
    private utilsService: UtilsService
  ) {}

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  warnings = {
    "immune-strength": 0,
    "gut-wall-strength": 0,
    "weight-reduction-support": 0,
    "fibre-degradation": 0,
    "potential-colon-problems-new": 0,
    "infection-alarm-new": 0,
    "fat-alarm-new": 0,
  };

  boxplotChart: any;
  barChart: any;
  doughnutChart: any;
  pieChart: any;
  testKitID: number;
  dashBacteria: IEyyo;
  nutrientValues: INutrientAdvice[];
  bacteriaSubCategories: Object;
  pdfUrls: JSON;
  pdfGenerated: boolean = false;
  showPdfSection: boolean = false;

  pieChartLabelsNl = [
    "Graan en graanproducten",
    "Fruit",
    "Noten en zaden",
    "Groenten",
    "Zuivel",
    "Oliën en boters",
    "Vlees",
    "Zeevruchten",
    "Peulvruchten",
    "Water",
  ];
  pieChartLabelsEn = [
    "Grains",
    "Fruits",
    "Nuts and seeds",
    "Vegetables",
    "Dairy",
    "Condiments (oils and butter)",
    "Meat",
    "Seafood",
    "Legumes",
    "Water",
  ];

  baseSidebuttons = [
    {
      id: 1,
      alias: "immune-strength",
      name: "DASHBOARD_OVERVIEW.GUT_HEALTH_TITLE",
      icon: "../../assets/shield_variant" + this.app.partnerVersion + ".png",
      title: "gut-health",
    },
    {
      id: 2,
      alias: "potential-colon-problems-new",
      name: "DASHBOARD_OVERVIEW.COLON_PROBLEM_TITLE",
      icon: "../../assets/colon_variant" + this.app.partnerVersion + ".png",
      title: "gut-challenges",
    },
    {
      id: 3,
      alias: "infection-alarm-new",
      name: "DASHBOARD_OVERVIEW.INFECTION_ALARM_TITLE",
      icon: "../../assets/virus_2_variant" + this.app.partnerVersion + ".png",
      title: "gut-challenges",
    },
    {
      id: 4,
      alias: "gut-wall-strength",
      name: "DASHBOARD_OVERVIEW.PRESENCE_OF_MICROORGANISMS_TITLE",
      icon: "../../assets/guts_variant" + this.app.partnerVersion + ".png",
      title: "gut-health",
    },
    {
      id: 5,
      alias: "fibre-degradation",
      name: "DASHBOARD_OVERVIEW.GAS_PROD_TITLE",
      icon: "../../assets/fart_variant" + this.app.partnerVersion + ".png",
      title: "fiber-degradation",
    },
    {
      id: 6,
      alias: "fat-alarm-new",
      name: "DASHBOARD_OVERVIEW.FAT_ALARM_TITLE",
      icon: "../../assets/body_fat_variant" + this.app.partnerVersion + ".png",
      title: "gut-challenges",
    },
    {
      id: 7,
      alias: "weight-reduction-support",
      name: "DASHBOARD_OVERVIEW.HEALTH_PROMOTING_MICROBIAL_TITLE",
      icon: "../../assets/substance_variant" + this.app.partnerVersion + ".png",
      title: "gut-health",
    },
  ];

  gutHealthSubCategories = this.baseSidebuttons.filter(
    (i) => i.title == "gut-health"
  );
  fibreDegradationSubCategories = this.baseSidebuttons.filter(
    (i) => i.title == "fiber-degradation"
  );
  gutChallengesSubCategories = this.baseSidebuttons.filter(
    (i) => i.title == "gut-challenges"
  );

  sidebuttons = [
    {
      id: 1,
      alias: "immune-strength",
      name: "Immune strength",
      icon: "../../assets/shield_variant-iprobio1.png",
      title: "gut-health",
    },
    {
      id: 2,
      alias: "gut-wall-strength",
      name: "Potential colon problem",
      icon: "../../assets/colon_variant.png",
      title: "gut-challenges",
    },
    {
      id: 3,
      alias: "weight-reduction-support",
      name: "Infection alarm",
      icon: "../../assets/virus_2_variant.png",
      title: "gut-challenges",
    },
    {
      id: 4,
      alias: "fiber-degradation",
      name: "Presence of healthy microorganisms",
      icon: "../../assets/guts_variant.png",
      title: "gut-health",
    },
    {
      id: 5,
      alias: "infection-alarm-new",
      name: "Gas production possibilities",
      icon: "../../assets/fart_variant.png",
      title: "fiber-degradation",
    },
    {
      id: 6,
      alias: "fat-alarm-new",
      name: "Fat alarm",
      icon: "../../assets/body_fat_variant.png",
      title: "gut-challenges",
    },
    {
      id: 7,
      alias: "potential-colon-problems-new",
      name: "Production of health-promoting microbial substances",
      icon: "../../assets/substance_variant.png",
      title: "gut-health",
    },
  ];

  bmiValue: any;
  bacteriaLevels;
  bacPercentage = 0;
  targetNutrientLevels = [20, 20, 30, 40, 50, 60, 70];
  adviceLevels = [1, 2, 3, 4, 5, 6, 7];

  carbohydratesValue = 0.001;
  fatValue = 0.001;
  saturatedFatValue = 0.001;

  gaugeColorVariants = {
    default: {
      normal: "#ad66d9",
      okay: "#cfbcf2",
      great: "#05e399",
      bad: "#fad408",
      aware: "#c8bfe7",
    },
    iprobio: {
      normal: "#62b3b3",
      okay: "#b5d2d2",
      great: "#6fd06f",
      bad: "#f299a1",
      aware: "#339d9c69",
    },
  };
  selectedColors = this.gaugeColorVariants["default"];
  partnerImgSrc = "";
  probioticCount: number = 0;
  prebioticSupplementCount: number = 0;

  async ngOnInit() {
    if (this.app.partnerVersion != "") {
      this.partnerImgSrc = "-" + this.app.partnerVersion;
      this.selectedColors = this.gaugeColorVariants[this.app.partnerVersion];
    }

    this.testKitID = Number(this.route.snapshot.paramMap.get("id"));

    this.getTakeHomeMessage();
    let diversityValue: number;

    // Exporting TestKitID for Usage in other pages & saving to local storage
    localStorage.setItem("currentTestKitID", this.testKitID.toString());
    this.testKitIdService.setTestKitID(this.testKitID);

    // TODO: add data for the 'your bacteria levels' chart
    sessionStorage.setItem(
      "Dashboard",
      JSON.stringify(
        (await this.dashboardService.GetDashboardBacteria(this.testKitID)).data
      )
    );

    this.dashBacteria = JSON.parse(sessionStorage.getItem("Dashboard"));
    this.getCategoryWarnings();

    if (!this.dashBacteria.graphs.find((x) => x.graph === "OTUs")) {
      console.log(this.route.params);
      this.router.navigateByUrl("/mantainance");
      return;
    }

    diversityValue = Number(
      this.dashBacteria.graphs.find((x) => x.graph === "Shannon").value
    );

    this.bmiValue = (await this.dashboardService.GetBmi(this.testKitID)).data;
    // this.targetNutrientLevels = (await this.dashboardService.GetNutrientLevels(testKitID)).data;
    // this.adviceLevels = (await this.dashboardService.GetAdviceLevels(testKitID)).data;

    this.bacteriaLevels = this.calculateBacteriaLevels(this.dashBacteria.top35);

    this.bacPercentage =
      (this.bacteriaLevels[0] +
        this.bacteriaLevels[1] +
        this.bacteriaLevels[2]) /
      100;

    const canvasDiversity = document.getElementById(
      "bacteriaDiversity"
    ) as HTMLCanvasElement;
    const ctxDiversity = canvasDiversity.getContext("2d");
    // var ctx2 = document.getElementById("bacteriaDiversity");
    const ref = [58, 265]; // Bacteria diversity
    const upperRange = 6;
    //let diversityScore = ((diversityValue - ref[0]) * 70) / (ref[1] - ref[0]) + 15;
    let diversityScore = ((diversityValue + 3) / upperRange) * 100;

    if (diversityScore < 0) {
      diversityScore = 0;
    }
    if (diversityScore > 100) {
      diversityScore = 100;
    }

    const conf = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: [15, 85, 100],
            value: diversityScore,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["normal"],
              this.selectedColors["great"],
            ],
            hoverBackgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["normal"],
              this.selectedColors["great"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        interaction: {
          mode: "point",
        },
        responsive: true,
        title: {
          display: false,
          text: "Gauge chart",
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    this.doughnutChart = new Chart(ctxDiversity, conf);

    // TODO: change 'data'to whatever we need for personalized advice
    // const canvasNutrient = document.getElementById(
    //   "pie-chart"
    // ) as HTMLCanvasElement;
    // const ctxNutrient = canvasNutrient.getContext("2d");
    // this.pieChart = new Chart(ctxNutrient, {
    //   type: "pie",
    //   data: {
    //     labels: [
    //       "Grains",
    //       "Fruits",
    //       "Nuts and seeds",
    //       "Vegetables",
    //       "Dairy",
    //       "Condiments (oils and butter)",
    //       "Meat",
    //       "Seafood",
    //       "Legumes",
    //       "Water",
    //     ],
    //     datasets: [
    //       {
    //         label: "Population (millions)",
    //         backgroundColor: [
    //           "#461862",
    //           "#5D2183",
    //           "#7429A3",
    //           "#8C31C4",
    //           "#9F4BD2",
    //           "#AD66D9",
    //           "#C28DE2",
    //           "#D3ADEB",
    //           "#E5CEF3",
    //           "#F6EFFB",
    //         ],
    //         hoverBackgroundColor: [
    //           "#461862",
    //           "#5D2183",
    //           "#7429A3",
    //           "#8C31C4",
    //           "#9F4BD2",
    //           "#AD66D9",
    //           "#C28DE2",
    //           "#D3ADEB",
    //           "#E5CEF3",
    //           "#F6EFFB",
    //         ],
    //         data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
    //       },
    //     ],
    //   },
    //   options: {
    //     title: {
    //       display: false,
    //     },
    //     legend: {
    //       display: true,
    //       position: "left",
    //       labels: {
    //         fontColor: "#000000",
    //       },
    //     },
    //     responsive: true,
    //     maintainAspectRatio: true,
    //     // pieceLabel: {
    //     //   render: 'label',
    //     //   fontColor: '#000',
    //     //   position: 'outside',
    //     //   segment: true
    //     // }
    //   },
    // });

    // Target nutrient chart
    if (sessionStorage.getItem("TargetNutValues-" + this.testKitID)) {
      this.nutrientValues = JSON.parse(
        sessionStorage.getItem("TargetNutValues-" + this.testKitID)
      );
      this.carbohydratesValue = this.nutrientValues.find(
        (x) => x.type === "carbohydrates"
      ).value;
      this.fatValue = this.nutrientValues.find((x) => x.type === "fat").value;
      this.saturatedFatValue = this.nutrientValues.find(
        (x) => x.type === "saturated_fat"
      ).value;
    }
    const confTargetNut = {
      type: "gauge",
      data: {
        datasets: [
          {
            data: [20, 36, 100],
            value:
              (this.carbohydratesValue +
                this.fatValue +
                this.saturatedFatValue) /
              3,
            backgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            hoverBackgroundColor: [
              this.selectedColors["bad"],
              this.selectedColors["great"],
              this.selectedColors["bad"],
            ],
            borderWidth: 2,
          },
        ],
      },
      options: {
        interaction: {
          mode: "point",
        },
        responsive: true,
        title: {
          display: false,
          text: "",
        },
        layout: {
          padding: {
            bottom: 30,
          },
        },
        needle: {
          // Needle circle radius as the percentage of the chart area width
          radiusPercentage: 2,
          // Needle width as the percentage of the chart area width
          widthPercentage: 3.2,
          // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
          lengthPercentage: 80,
          // The color of the needle
          color: "rgba(0, 0, 0, 1)",
        },
        valueLabel: {
          display: false,
          formatter: Math.round,
        },
      },
    };

    const canvasTargetNut = document.getElementById(
      "target-nut"
    ) as HTMLCanvasElement;
    const ctxTargetNut = canvasTargetNut.getContext("2d");

    this.doughnutChart = new Chart(ctxTargetNut, confTargetNut);

    const coll = document.getElementsByClassName("collapsible");
    let i;

    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.toggle("active");
        const content = this.nextElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }

    this.updateChartLabels();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateChartLabels();
    });

    this.resizeObservable$ = fromEvent(window, "resize");
    this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
      // @ts-ignore
      //this.pieChart.options.legend.display = !(evt.target.innerWidth < 480);
      //this.pieChart.update();
    });

    // Get the language code id
    let langId;
    if (localStorage.getItem("language") == "en") {
      langId = 1;
    } else if (localStorage.getItem("language") == "it") {
      langId = 3;
    } else if (localStorage.getItem("language") == "sk") {
      langId = 4;
    } else {
      langId = 2;
    }

    // Retrieve the pdf url
    if (!this.pdfUrls || Object.keys(this.pdfUrls).length === 0) {
      try {
        this.pdfUrls = await this.utilsService.GetDashboardPdfLinks(
          this.testKitID,
          this.app.selectedLanguage
        );
        if (this.pdfUrls && Object.keys(this.pdfUrls).length > 0) {
          this.showPdfSection = true;
          this.pdfGenerated = true;
          document.getElementById("pdfButton").setAttribute("style", "100%");
        }
      } catch (error) {
        console.error("Error with retrieving PDFs: " + error);
      }
    }
    

    // Get Pro/Prebiotics data
    this.getProbioticAndPrebioticDataCount();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  // ngOnDestroy() {
  //   this.resizeSubscription$.unsubscribe();
  // }

  // tslint:disable-next-line:use-lifecycle-interface
  async ngAfterViewInit() {
    const testKitID = Number(this.route.snapshot.paramMap.get("id"));
    await this.setTargetNutChart(testKitID);
  }

  async setTargetNutChart(testKitID) {
    this.nutrientValues = await (
      await this.dietaryDashService.GetNutrientAdvice(testKitID)
    ).data;

    this.carbohydratesValue = this.nutrientValues.find(
      (x) => x.type === "carbohydrates"
    ).value;
    this.fatValue = this.nutrientValues.find((x) => x.type === "fat").value;
    this.saturatedFatValue = this.nutrientValues.find(
      (x) => x.type === "saturated_fat"
    ).value;

    this.doughnutChart.data.datasets.forEach((dataset) => {
      dataset.value =
        (this.carbohydratesValue + this.fatValue + this.saturatedFatValue) / 3;
    });
    this.doughnutChart.update();

    sessionStorage.setItem(
      "TargetNutValues-" + testKitID,
      JSON.stringify(this.nutrientValues)
    );
  }

  updateSideButtons() {
    let i = 0;
    for (const step of this.baseSidebuttons) {
      this.translate.get(step.name).subscribe((text: string) => {
        this.sidebuttons[i].name = text;
      });
      i++;
    }
  }

  updateChartLabels() {
    // if (this.translate.currentLang.toString() === "nl") {
    //   this.pieChart.data.labels = this.pieChartLabelsNl;
    // } else {
    //   this.pieChart.data.labels = this.pieChartLabelsEn;
    // }
    // this.pieChart.update();
  }

  calculateBacteriaLevels(bacteria) {
    const bacteriaToIgnore = [
      "Alistipes",
      "Flavobacteriaceae",
      "Acetobacter",
      "Acinetobacter",
      "[Ruminococcus]_gauvreauii_group",
      "Ruminococcus",
      "Clostridium",
      "Christensenellaceae",
    ];
    const otherBacteria = [
      "Dorea",
      "Fusicatenibacter",
      "Lachnoclostridium",
      "Lachnospira",
      "Lachnospiraceae",
      "Methanobrevibacter",
      "Methanosphaera",
      "Prevotella",
      "Subdoligranulum",
      "Ruminiclostridium",
      "Clostridium_sensu_stricto_1",
    ];

    const badBacteria = [
      "Bilophila",
      "Desulfovibrio",
      "Bacteroides",
      "Escherichia-Shigella",
      "Klebsiella",
      "Sutterella",
      "Fusobacterium",
    ];
    const levels = [0, 0, 0, 0];

    bacteria.forEach((x) => {
      if (!bacteriaToIgnore.includes(x.bacteria)) {
        if (otherBacteria.includes(x.bacteria)) {
          if (x.status === "high") {
            levels[2] += 1;
          }
          if (x.status === "low") {
            levels[2] += 1;
          }
          return;
        } else if (badBacteria.includes(x.bacteria)) {
          if (x.status === "low") {
            levels[3] += 1;
          }
          if (x.status === "avg") {
            levels[1] += 1;
          }
          if (x.status === "high") {
            console.log(x.bacteria);
            levels[0] += 1;
          }
          return;
        }
        if (x.status === "low") {
          console.log(x.bacteria);
          levels[0] += 1;
        }
        if (x.status === "avg") {
          levels[1] += 1;
        }
        if (x.status === "high") {
          levels[3] += 1;
        }
      }
    });
    return levels;
  }

  // Very slow download with some elements - More testing to be done.
  printPdf() {
    const hiddenContainer = document.getElementById("pdf-hidden-container");
    hiddenContainer.classList.remove("hidden-for-pdf");

    const cardIds = [
      "bmi-card",
      "bacteria-diversity-card",
      "bacteria-levels-card",
      "target-values-card",
    ];
    const detailIds = [
      ["bmi-details"],
      [
        "bacteria-diversity-details-1",
        "bacteria-diversity-details-2",
        "bacteria-diversity-details-3",
      ],
      ["bacteria-levels-details"],
      ["target-values-details"],
    ];

    const processCard = async (cardId: string, detailIdsForCard: string[]) => {
      const cardElement = document.getElementById(cardId);

      if (cardElement && detailIdsForCard.length > 0) {
        for (const detailId of detailIdsForCard) {
          const detailElement = document.getElementById(detailId);
          if (detailElement) {
            cardElement.appendChild(detailElement.cloneNode(true));
          }
        }
      }
    };

    const processAllCards = async () => {
      for (let i = 0; i < cardIds.length; i++) {
        await processCard(cardIds[i], detailIds[i]);
      }
    };

    processAllCards().then(() => {
      const opt = {
        margin: [1, 0.5],
        filename: "dashboard.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
        pagebreak: { mode: "avoid-all", before: ".avoid-pagebreak-before" },
        onclone: (clonedContent: HTMLElement) => {
          const hiddenElements =
            clonedContent.querySelectorAll(".hidden-for-pdf");
          Array.from(hiddenElements).forEach((element: HTMLElement) => {
            if (element) {
              element.style.visibility = "visible";
            }
          });
        },
      };

      html2pdf()
        .from(hiddenContainer)
        .set(opt)
        .save()
        .then(() => {
          setTimeout(() => {
            hiddenContainer.classList.add("hidden-for-pdf");
          }, 1000);
        });
    });
  }

  getCategoryWarnings() {
    this.dashBacteria.top35.forEach((elem) => {
      if (BacteriaSubCategories.IMMUNE_STRENGTH.includes(elem.bacteria)) {
        if (elem.status == "low") this.warnings["immune-strength"]++;
        return;
      }
      if (BacteriaSubCategories.GUT_WALL_STRENGTH.includes(elem.bacteria)) {
        if (elem.status == "low") this.warnings["gut-wall-strength"]++;
        return;
      }
      if (
        BacteriaSubCategories.WEIGHT_REDUCTION_SUPPORT.includes(elem.bacteria)
      ) {
        if (elem.status == "low") this.warnings["weight-reduction-support"]++;
        return;
      }
      if (BacteriaSubCategories.FIBRE_DEGRADATION.includes(elem.bacteria)) {
        if (elem.status == "low" || elem.status == "high")
          this.warnings["fibre-degradation"]++;
        return;
      }
      if (
        BacteriaSubCategories.POTENTIAL_COLON_PROBLEMS_NEW.includes(
          elem.bacteria
        )
      ) {
        if (elem.status == "high")
          this.warnings["potential-colon-problems-new"]++;
        return;
      }
      if (BacteriaSubCategories.INFECTION_ALARM_NEW.includes(elem.bacteria)) {
        if (elem.status == "high") this.warnings["infection-alarm-new"]++;
        return;
      }
      if (BacteriaSubCategories.FAT_ALARM_NEW.includes(elem.bacteria)) {
        if (elem.status == "high") this.warnings["fat-alarm-new"]++;
        return;
      }
    });
  }

  async getTakeHomeMessage() {
    const response = await this.dashboardService.GetTakeHomeMessage(
      parseInt(localStorage.getItem("s_id" + this.testKitID)),
      localStorage.getItem("k_uid" + this.testKitID),
      localStorage.getItem("language").toUpperCase()
    );

    response.data["generated_categories"].forEach((element) => {
      this.takeHomeData["focusCategories"].push(element.category);
    });

    response.data["generated_options"].forEach((element) => {
      let temp = {
        name: element.food_item,
        foodGroup: element.food_group,
      };
      this.takeHomeData["foodRecommendation"].push(temp);
    });

    response.data["generated_rules"].forEach((element) => {
      switch (element.show_meal_type) {
        case "Breakfast":
          this.takeHomeData["meals"]["breakfast"]++;
          break;
        case "Lunch":
          this.takeHomeData["meals"]["lunch"]++;
          break;
        case "Dinner":
          this.takeHomeData["meals"]["dinner"]++;
          break;
      }
    });
  }

  async loadPdf() {
    // Open the pdf in a new tab
    if (this.pdfGenerated == true) {
      window.open(this.pdfUrls[this.translate.currentLang], "_blank");
    }
  }

  // Prebiotic/Probiotic data
  getProbioticAndPrebioticDataCount() {
    this.dietaryDashService
    .GetSupplementAdvice(this.testKitID, this.translate.getDefaultLang())
      .then((data: IProbioticPrebioticAdvice | undefined) => {
        if (data) {
          // Set the count values
          this.probioticCount = data.probiotic_results.length;
          this.prebioticSupplementCount = data.prebiotic_supplements.length;

          console.log(`Probiotic count: ${this.probioticCount}`);
          console.log(
            `Prebiotic supplement count: ${this.prebioticSupplementCount}`
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching probiotic and prebiotic data: ", error);
      });
  }
}
